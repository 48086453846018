import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight } from 'lucide-react';

import CyberLoading from './components/loading/CyberLoading';
import CyberParticles from './components/loading/CyberParticles';
import CyberTerminal from './components/terminal/CyberTerminal';
import GlitchText from './components/ui/GlitchText';
import EnhancedLogo from './components/ui/EnhancedLogo';
import ProgressTimeline from './components/progress/ProgressTimeline';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showProgress, setShowProgress] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = useCallback((e) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [handleMouseMove]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen bg-black overflow-hidden relative" onMouseMove={handleMouseMove}>
      <AnimatePresence mode="wait">
        {isLoading ? (
          <CyberLoading />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="w-full h-full"
          >
            {/* Custom Cursor */}
            <div 
              className="fixed w-4 h-4 border-2 border-purple-500/50 rounded-full pointer-events-none z-50 mix-blend-screen"
              style={{
                left: cursorPosition.x - 8,
                top: cursorPosition.y - 8,
                transition: 'transform 0.1s',
                transform: 'translate(0, 0)'
              }}
            >
              <div className="absolute inset-0 bg-purple-500/20 rounded-full animate-pulse" />
            </div>

            <CyberParticles />
            
            {/* Background Elements */}
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_rgba(98,_0,_255,_0.1)_0%,_transparent_70%)]" />
            
            {/* Moving Grid */}
            <motion.div 
              className="absolute inset-0 opacity-20"
              animate={{
                backgroundPosition: ['0px 0px', '30px 30px']
              }}
              transition={{
                duration: 20,
                repeat: Infinity,
                ease: "linear"
              }}
              style={{
                backgroundImage: 'linear-gradient(rgba(147, 51, 234, 0.2) 1px, transparent 1px), linear-gradient(90deg, rgba(147, 51, 234, 0.2) 1px, transparent 1px)',
                backgroundSize: '30px 30px',
                transform: 'perspective(1000px) rotateX(60deg)',
                transformOrigin: 'center'
              }}
            />

            {/* Main Content */}
            <div className="relative z-10 min-h-screen flex flex-col items-center justify-center p-8">
              {/* Matrix Rain Effect */}
              <div className="absolute inset-0 overflow-hidden pointer-events-none">
                {[...Array(50)].map((_, i) => (
                  <motion.div
                    key={i}
                    className="absolute text-purple-500/30 font-mono"
                    style={{
                      fontSize: `${Math.random() * 12 + 8}px`,
                      x: Math.random() * window.innerWidth,
                    }}
                    initial={{ y: -20 }}
                    animate={{ y: window.innerHeight + 20 }}
                    transition={{
                      duration: Math.random() * 2 + 1,
                      repeat: Infinity,
                      ease: "linear",
                      delay: Math.random() * 2
                    }}
                  >
                    {String.fromCharCode(33 + Math.floor(Math.random() * 94))}
                  </motion.div>
                ))}
              </div>

              {/* Logo */}
              <div className="flex flex-col items-center mb-12">
                <motion.div
                  className="relative"
                  animate={{
                    rotateY: [0, 10, 0, -10, 0],
                    translateY: [0, -5, 0, 5, 0]
                  }}
                  transition={{
                    duration: 5,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                >
                  <EnhancedLogo 
                    src="https://res.cloudinary.com/ddplrbqet/image/upload/v1732224041/%C3%87al%C4%B1%C5%9Fma_Y%C3%BCzeyi_1_h0vdsf.png"
                    className="relative z-10"
                  />
                </motion.div>
              </div>

              {/* Terminal and Progress Section */}
              <div className="w-full max-w-2xl space-y-6">
                <CyberTerminal className="bg-black/80 backdrop-blur-md border border-purple-500/20 rounded-lg" />

                <motion.button
                  className="w-full px-6 py-3 rounded-lg bg-purple-500/10 text-purple-400 
                            hover:bg-purple-500/20 transition-colors flex items-center justify-center gap-2"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setShowProgress(prev => !prev)}
                >
                  <span>{showProgress ? 'Gelişimi Gizle' : 'Gelişimi Göster'}</span>
                  <motion.span
                    animate={{ rotate: showProgress ? 90 : 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <ChevronRight size={20} />
                  </motion.span>
                </motion.button>
                
                <AnimatePresence mode="sync">
                  {showProgress && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="overflow-hidden"
                    >
                      <div className="bg-black/80 backdrop-blur-md border border-purple-500/20 rounded-lg p-6">
                        <ProgressTimeline />
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>

            {/* Footer Text */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
              className="absolute bottom-8 left-1/2 -translate-x-1/2"
            >
              <GlitchText>
                <span className="text-white/90 text-sm tracking-wider">
                  Ekosistemi İnşa Ediliyor
                </span>
              </GlitchText>
            </motion.div>

          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default App;