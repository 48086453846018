import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const EnhancedLogo = ({ src, className }) => {
  const [isGlitching, setIsGlitching] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Math.random() > 0.95) {
        setIsGlitching(true);
        setTimeout(() => setIsGlitching(false), 200);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      className={`relative w-48 h-48 cursor-pointer ${className}`}
      whileHover={{ scale: 1.05 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <motion.div
        className="relative w-full h-full"
        animate={
          isGlitching 
            ? {
                x: [-2, 2, -2, 0],
                y: [2, -2, 2, 0],
              }
            : {}
        }
        transition={{ duration: 0.2 }}
      >
        {/* Circular Logo Container */}
        <div className="w-full h-full rounded-full overflow-hidden relative">
          <img 
            src={src}
            alt="WoGamer Logo"
            className="w-full h-full object-contain rounded-full relative z-10"
            style={{
              filter: 'brightness(1.2) contrast(1.1)',
            }}
          />
          
          {/* Glitch Effects */}
          {isGlitching && (
            <>
              <div
                className="absolute inset-0 rounded-full"
                style={{
                  background: 'rgba(255,0,255,0.2)',
                  transform: 'translate(-5px, 0)',
                  mixBlendMode: 'screen',
                }}
              />
              <div
                className="absolute inset-0 rounded-full"
                style={{
                  background: 'rgba(0,255,255,0.2)',
                  transform: 'translate(5px, 0)',
                  mixBlendMode: 'screen',
                }}
              />
            </>
          )}

          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-tr from-purple-600/30 to-blue-500/30 mix-blend-overlay rounded-full" />
        </div>

        {/* Glow Effect */}
        <motion.div
          className="absolute -inset-4 rounded-full opacity-75 blur-xl"
          animate={{
            background: [
              'radial-gradient(circle at center, rgba(139, 92, 246, 0.4), transparent 70%)',
              'radial-gradient(circle at center, rgba(59, 130, 246, 0.4), transparent 70%)',
              'radial-gradient(circle at center, rgba(139, 92, 246, 0.4), transparent 70%)'
            ]
          }}
          transition={{ duration: 2, repeat: Infinity }}
        />

        {/* Hover Rings */}
        {isHovered && (
          <>
            <motion.div
              className="absolute inset-0 border-2 border-purple-500/20 rounded-full"
              initial={{ scale: 1.2, opacity: 0 }}
              animate={{ 
                scale: [1.2, 1.4],
                opacity: [0, 1, 0],
                rotate: [0, 180]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            />
            <motion.div
              className="absolute inset-0 border-2 border-blue-500/20 rounded-full"
              initial={{ scale: 1.2, opacity: 0 }}
              animate={{ 
                scale: [1.4, 1.6],
                opacity: [0, 1, 0],
                rotate: [180, 0]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            />
          </>
        )}
      </motion.div>

      {/* Scan Line Effect */}
      <motion.div
        className="absolute inset-0 overflow-hidden rounded-full pointer-events-none"
        style={{ mixBlendMode: 'overlay' }}
      >
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-full h-[1px] bg-gradient-to-r from-transparent via-purple-500/30 to-transparent"
            initial={{ y: -10 }}
            animate={{ y: 300 }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              delay: i * 0.1,
              ease: "linear"
            }}
            style={{
              top: `${(i * 10)}%`,
            }}
          />
        ))}
      </motion.div>
    </motion.div>
  );
};

export default EnhancedLogo;