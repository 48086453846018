import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Terminal, AlertCircle, Check, Timer, Server, Shield } from 'lucide-react';
import { chatWithGpt } from '../../utils/mockChatApi';

const logTypes = {
  success: { icon: Check, color: 'text-green-500', bg: 'bg-green-500/10' },
  error: { icon: AlertCircle, color: 'text-red-500', bg: 'bg-red-500/10' },
  warning: { icon: Timer, color: 'text-yellow-500', bg: 'bg-yellow-500/10' },
  info: { icon: Server, color: 'text-blue-500', bg: 'bg-blue-500/10' },
  system: { icon: Shield, color: 'text-white-500', bg: 'bg-purple-500/10' }
};

const CyberTerminal = ({ className }) => {
  const [commands, setCommands] = useState([]);
  const [currentCommand, setCurrentCommand] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const terminalRef = useRef(null);

  useEffect(() => {
    const systemLogs = [
      { type: 'system', text: 'Sistem başlatılıyor...' },
      { type: 'info', text: 'Sunucular kontrol ediliyor...' },
      { type: 'success', text: 'Sistem aktif.' },
      { type: 'warning', text: 'Bakım modu devam ediyor...' },
    ];

    const timeouts = systemLogs.map((log, index) => (
      setTimeout(() => {
        setCommands(prev => {
          if (prev.some(p => p.text === log.text)) return prev;
          return [...prev, log];
        });
        terminalRef.current?.scrollTo(0, terminalRef.current.scrollHeight);
      }, index * 1000)
    ));
  
    return () => timeouts.forEach(timeout => clearTimeout(timeout));
  }, []);

  const handleCommand = async (cmd) => {
    if (!cmd.trim()) return;
  
    setCommands(prev => [...prev, { type: 'system', text: `> ${cmd}` }]);
    setCurrentCommand('');
    setLoading(true);
  
    try {
      const builtInCommands = ['yardım', 'sistem', 'hakkında', 'temizle', 'mvp'];
      
      if (builtInCommands.includes(cmd.toLowerCase())) {
        const builtInResponse = {
          yardım: {
            type: 'success',
            text: `Kullanılabilir komutlar:
sistem - Sistem durumunu verir.
hakkında - WoGamer hakkında bilgi verir.
temizle - Terminali temizler.
mvp - Lansman planı
Diğer mesajlar asistan tarafından yanıtlanacaktır.`
          },
          sistem: {
            type: 'success',
            text: `WoGamer Sistem Durumu:
Backend: %30 
Frontend: %10 
Security: %50 
Veritabanı: %50`
          },
          hakkında: {
            type: 'info',
            text: "WoGamer - Yeni Nesil Oyun Ekosistemi\nOyuncular için, oyuncular tarafından..."
          },
          temizle: null,
          mvp: {
            type: 'warning',
            text: "Tahmini lansman: 5 ay\nBeta test: 3 ay içinde"
          }
        }[cmd.toLowerCase()];
  
        if (cmd.toLowerCase() === 'temizle') {
          setCommands([]);
        } else {
          setCommands(prev => [...prev, builtInResponse]);
        }
      } else {
        const response = await chatWithGpt(cmd);
        
        if (response) {
          setCommands(prev => [...prev, { 
            type: 'info', 
            text: response 
          }]);
        } else {
          throw new Error('Yanıt alınamadı');
        }
      }
    } catch (error) {
      console.error('Terminal hatası:', error);
      setCommands(prev => [...prev, { 
        type: 'error', 
        text: `Hata: ${error.message}` 
      }]);
    } finally {
      setLoading(false);
      terminalRef.current?.scrollTo(0, terminalRef.current.scrollHeight);
    }
  };

  return (
    <div className={`${className} overflow-hidden`}>
      {/* Terminal Header */}
      <div className="flex items-center justify-between p-3 bg-purple-500/10 border-b border-purple-500/20">
  <div className="flex items-center gap-2">
    <Terminal size={16} className="text-purple-500" />
    <span className="text-sm text-purple-400 font-medium">WoGamer Terminal v1</span>
  </div>
  <div className="flex gap-2">
    <div className="w-2.5 h-2.5 rounded-full bg-red-500/60 hover:bg-red-500/80 transition-colors" />
    <div className="w-2.5 h-2.5 rounded-full bg-yellow-500/60 hover:bg-yellow-500/80 transition-colors" />
    <div className="w-2.5 h-2.5 rounded-full bg-green-500/60 hover:bg-green-500/80 transition-colors" />
  </div>
</div>

      {/* Terminal Content */}
      <div 
  ref={terminalRef}
  className="h-80 overflow-y-auto p-5 font-mono text-sm space-y-2.5 scrollbar-thin 
             scrollbar-thumb-purple-500/30 scrollbar-track-transparent"
  onClick={() => inputRef.current?.focus()}
>
  {commands.map((cmd, i) => {
    const LogIcon = logTypes[cmd.type]?.icon;
    return (
      <motion.div
        key={i}
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        className={`flex items-start gap-2.5 p-2 rounded-md ${
          cmd.text.startsWith('>') ? '' : 
          `${logTypes[cmd.type]?.bg} border-l-2 border-${cmd.type === 'success' ? 'green' : 
            cmd.type === 'error' ? 'red' : 
            cmd.type === 'warning' ? 'yellow' : 
            cmd.type === 'info' ? 'blue' : 'purple'}-500/50`
        }`}
      >
        {LogIcon && <LogIcon size={15} className={`${logTypes[cmd.type]?.color} mt-0.5`} />}
        <pre className={`font-mono whitespace-pre-wrap ${logTypes[cmd.type]?.color} text-[13px] leading-relaxed`}>
          {cmd.text}
        </pre>
      </motion.div>
    );
  })}
  
  {/* Input Area - güncellenmiş */}
  <div className="flex items-center gap-2.5 pt-2">
    <span className="text-green-500 text-lg">❯</span>
    <input
      ref={inputRef}
      value={currentCommand}
      onChange={e => setCurrentCommand(e.target.value)}
      onKeyPress={e => e.key === 'Enter' && handleCommand(currentCommand)}
      className="bg-transparent outline-none text-purple-400 w-full placeholder-purple-500/40 
                focus:placeholder-purple-500/60 transition-colors text-[13px]"
      placeholder="Komut girin veya bir şeyler sorun..."
      disabled={loading}
    />
    {loading && (
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
      >
        <Terminal size={15} className="text-purple-500" />
      </motion.div>
    )}
  </div>
    </div>
    </div>
  );
};

export default CyberTerminal;