// mockChatApi.js
export const mockResponses = {
  'selam': 'Merhaba! Size nasıl yardımcı olabilirim?',
  'merhaba': 'Merhaba! WoGamer asistanı olarak size nasıl yardımcı olabilirim?',
  'nasılsın': 'İyiyim, teşekkür ederim! Siz nasılsınız?',
  'wogamer nedir': 'WoGamer, yeni nesil bir oyun ekosistemidir. Oyuncular için oyuncular tarafından geliştirilmektedir.',
  'ne zaman çıkacak': 'WoGamer\'ın beta sürümü 5 ay içinde, tam sürümü ise 12 ay içinde yayınlanacak.',
  'özellikler': `WoGamer'ın temel özellikleri:\n- Sosyal özellikler\n- Turnuva sistemleri`,
  'destek': 'support@wogamer.com adresinden 7/24 destek alabilirsiniz.',
  'dil': 'Desteklenen diller: Türkçe, İngilizce, Almanca, İspanyolca, Fransızca',
  'hedefler': 'Günlük ve haftalık görevler ile ekstra ödüller kazanın',
  'teknik': 'Sunucu lokasyonları: Türkiye'
  };
  
  export const chatWithGpt = async (prompt) => {
    // Simüle edilmiş gecikme
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    try {
      const lowercasePrompt = prompt.toLowerCase();
      
      // Yanıtları kontrol et
      for (const [key, value] of Object.entries(mockResponses)) {
        if (lowercasePrompt.includes(key)) {
          return value;
        }
      }
      
      // Varsayılan yanıt
      return `Anladım, "${prompt}" hakkında daha fazla bilgi almak istiyorsunuz. Lütfen "yardım" komutunu çağırın. Başka bir sorunuz var mı?`;
      
    } catch (error) {
      console.error('Mock API Hatası:', error);
      throw new Error('Mesajınız işlenirken bir hata oluştu');
    }
  };