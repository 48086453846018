import React from 'react';
import { motion } from 'framer-motion';

const CyberParticles = () => {
  return (
    <>
      {/* Sol taraftaki siber çizgiler */}
      <div className="fixed left-0 top-0 h-screen w-32 pointer-events-none">
        {[...Array(5)].map((_, i) => (
          <motion.div
            key={`left-${i}`}
            className="absolute h-px bg-purple-500/20"
            style={{
              width: Math.random() * 100 + 50,
              left: Math.random() * 20,
              top: `${(i + 1) * 15}%`
            }}
            animate={{
              scaleX: [0, 1, 1, 0],
              opacity: [0, 0.5, 0.5, 0],
              x: ['0%', '0%', '100%', '100%']
            }}
            transition={{
              duration: Math.random() * 3 + 2,
              repeat: Infinity,
              ease: "linear"
            }}
          />
        ))}
      </div>

      {/* Sağ taraftaki siber çizgiler */}
      <div className="fixed right-0 top-0 h-screen w-32 pointer-events-none">
        {[...Array(5)].map((_, i) => (
          <motion.div
            key={`right-${i}`}
            className="absolute h-px bg-purple-500/20"
            style={{
              width: Math.random() * 100 + 50,
              right: Math.random() * 20,
              top: `${(i + 1) * 15}%`
            }}
            animate={{
              scaleX: [0, 1, 1, 0],
              opacity: [0, 0.5, 0.5, 0],
              x: ['0%', '0%', '-100%', '-100%']
            }}
            transition={{
              duration: Math.random() * 3 + 2,
              repeat: Infinity,
              ease: "linear"
            }}
          />
        ))}
      </div>
    </>
  );
};

export default CyberParticles;