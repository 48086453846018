import React from 'react';
import { motion } from 'framer-motion';
import { Database, Code, Shield, Trophy, Users, Timer, Clock } from 'lucide-react';

const phases = [
  {
    icon: Database,
    name: 'Veritabanı',
    progress: 50,
    details: 'Veritabanı Tasarımı ve Performans iyileştirmeleri.',
    status: 'inProgress'
  },
  {
    icon: Code,
    name: 'Backend',
    progress: 30,
    details: '',
    status: 'inProgress'
  },
  {
    icon: Code,
    name: 'Frontend',
    progress: 10,
    details: 'Kullanıcı arayüzü ve deneyim tasarımı',
    status: 'inProgress'
  },
  {
    icon: Shield,
    name: 'Security',
    progress: 50,
    details: 'Güvenlik protokolleri ve tespit algoritmaları',
    status: 'inProgress'
  },
  {
    icon: Users,
    name: 'Topluluk Özellikleri',
    progress: 25,
    details: 'Sosyal özellikler ve oyuncu etkileşimleri',
    status: 'pending'
  },
  {
    icon: Clock,
    name: 'MVP',
    progress: 35,
    details: 'Geliştirme aşaması',
    status: 'active'
  }
];


const ProgressTimeline = () => {
  return (
    <div>
      <div className="space-y-6">
        {phases.map((phase, index) => {
          const Icon = phase.icon;
          return (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="relative"
            >
              <div className="flex items-center gap-4 mb-2">
                <div className={`p-2 rounded-lg ${
                  phase.status === 'active' ? 'bg-green-500/10' :
                  phase.status === 'inProgress' ? 'bg-yellow-500/10' :
                  'bg-purple-500/10'
                }`}>
                  <Icon size={20} className={
                    phase.status === 'active' ? 'text-green-500' :
                    phase.status === 'inProgress' ? 'text-yellow-500' :
                    'text-purple-500'
                  } />
                </div>
                
                <div className="flex-1">
                  <div className="flex justify-between items-center mb-1">
                    <span className="text-purple-400 font-medium">{phase.name}</span>
                    <span className="text-purple-400">{phase.progress}%</span>
                  </div>
                  <div className="h-2 bg-purple-900/30 rounded-full overflow-hidden">
                    <motion.div
                      className="h-full bg-gradient-to-r from-purple-500 to-blue-500"
                      initial={{ width: 0 }}
                      animate={{ width: `${phase.progress}%` }}
                      transition={{ duration: 1, delay: index * 0.2 }}
                    />
                  </div>
                  <div className="text-xs text-purple-400/50 mt-1">{phase.details}</div>
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>

      {/* Lansman Bilgisi */}
      <div className="mt-6 pt-4 border-t border-purple-500/10 text-center">
        <motion.div
          className="flex items-center justify-center gap-2 text-emerald-400/80"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
        >
          <Timer size={14} />
          <span>Tahmini Lansman: 5 Ay</span>
        </motion.div>
      </div>
    </div>
  );
};

export default ProgressTimeline;