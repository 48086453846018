import React, { useState, useEffect } from 'react';

const GlitchText = ({ children, className }) => {
  const [isGlitching, setIsGlitching] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsGlitching(true);
      setTimeout(() => setIsGlitching(false), 200);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`relative ${className}`}>
      <div className={`${isGlitching ? 'animate-glitch' : ''}`}>{children}</div>
      {isGlitching && (
        <>
          <div className="absolute top-0 left-0 w-full h-full text-[#0ff] translate-x-[-2px]">
            {children}
          </div>
          <div className="absolute top-0 left-0 w-full h-full text-[#f0f] translate-x-[2px]">
            {children}
          </div>
        </>
      )}
    </div>
  );
};

export default GlitchText;