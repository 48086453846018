import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Shield, Database, Code, Zap, Target, FlameKindling, Globe, MonitorCheck, Trophy } from 'lucide-react';

const loadingPhases = [
  { icon: Shield, text: "Güvenlik Protokolleri Başlatılıyor", color: "text-green-500", threshold: 25 },
  { icon: Database, text: "Veritabanı Bağlantısı Kuruluyor", color: "text-purple-500", threshold: 50 },
  { icon: Code, text: "Modüller Derleniyor", color: "text-blue-500", threshold: 75},
  { icon: MonitorCheck, text: "Neredeyse Bitti", color: "text-white-500", threshold: 100},
  { icon: Trophy, text: "Yönlendiriliyor...", color: "text-yellow-500", threshold: 125 }
];

const CyberLoading = () => {
  const [progress, setProgress] = useState(0);
  const [currentPhase, setCurrentPhase] = useState(0);
  const [glitchActive, setGlitchActive] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [powerBoost, setPowerBoost] = useState(false);
  const [interactionPoints, setInteractionPoints] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + (powerBoost ? 2 : 1);
      });
    }, 50);

    return () => clearInterval(interval);
  }, [powerBoost]);

  useEffect(() => {
    if (progress < loadingPhases[0].threshold) setCurrentPhase(0);
    else if (progress < loadingPhases[1].threshold) setCurrentPhase(1);
    else if (progress < loadingPhases[2].threshold) setCurrentPhase(2);
    else if (progress < loadingPhases[3].threshold) setCurrentPhase(3);
    else if (progress < loadingPhases[4].threshold) setCurrentPhase(4);
  }, [progress]);

  useEffect(() => {
    if (glitchActive) {
      const timeout = setTimeout(() => setGlitchActive(false), 500);
      return () => clearTimeout(timeout);
    }
  }, [glitchActive]);

  const handleScreenClick = (e) => {
    const point = {
      x: e.clientX,
      y: e.clientY,
      id: Date.now()
    };
    setInteractionPoints(prev => [...prev, point]);
    setGlitchActive(true);
    setClickCount(prev => prev + 1);
    
    if (clickCount > 5 && !powerBoost) {
      setPowerBoost(true);
    }
  };

  useEffect(() => {
    interactionPoints.forEach(point => {
      setTimeout(() => {
        setInteractionPoints(prev => 
          prev.filter(p => p.id !== point.id)
        );
      }, 2000);
    });
  }, [interactionPoints]);

  return (
    <motion.div 
      className="fixed inset-0 bg-black/95 z-50 flex items-center justify-center overflow-hidden cursor-crosshair"
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      onClick={handleScreenClick}
    >
      <AnimatePresence>
        {interactionPoints.map(point => (
          <motion.div
            key={point.id}
            initial={{ opacity: 1, scale: 0 }}
            animate={{ scale: 1.5, opacity: 0 }}
            exit={{ scale: 2, opacity: 0 }}
            transition={{ duration: 1 }}
            className="absolute w-16 h-16 border-2 border-purple-500/50 rounded-full"
            style={{
              left: point.x - 32,
              top: point.y - 32
            }}
          />
        ))}
      </AnimatePresence>

      <div className="max-w-md w-full space-y-5 p-6 relative">
        <motion.div 
          className={`w-32 h-32 mx-auto rounded-full flex items-center justify-center
            ${glitchActive ? 'animate-glitch' : ''}`}
          style={{
            background: 'radial-gradient(circle, rgba(147,51,234,0.3) 0%, rgba(0,0,0,0) 70%)'
          }}
        >
          <motion.div
            className="relative w-full h-full"
            animate={{
              rotate: 360,
              scale: powerBoost ? [1, 1.2, 1] : [1, 1.1, 1],
            }}
            transition={{
              duration: powerBoost ? 2 : 3,
              repeat: Infinity,
              ease: "linear"
            }}
          >
            <div className="w-full h-full rounded-full overflow-hidden relative">
              <img 
                src="https://res.cloudinary.com/ddplrbqet/image/upload/v1732224041/%C3%87al%C4%B1%C5%9Fma_Y%C3%BCzeyi_1_h0vdsf.png"
                alt="WoGamer Logo"
                className="w-full h-full object-contain rounded-full relative z-10"
                style={{
                  filter: 'brightness(1.2) contrast(1.1)',
                }}
              />
              
              {glitchActive && (
                <>
                  <div
                    className="absolute inset-0 rounded-full"
                    style={{
                      background: 'rgba(255,0,255,0.2)',
                      transform: 'translate(-5px, 0)',
                      mixBlendMode: 'screen',
                    }}
                  />
                  <div
                    className="absolute inset-0 rounded-full"
                    style={{
                      background: 'rgba(0,255,255,0.2)',
                      transform: 'translate(5px, 0)',
                      mixBlendMode: 'screen',
                    }}
                  />
                </>
              )}

              <div className="absolute inset-0 bg-gradient-to-tr from-purple-600/30 to-blue-500/30 mix-blend-overlay rounded-full" />
            </div>

            {Array.from({ length: 4 }).map((_, i) => (
              <motion.div
                key={i}
                className="absolute inset-0"
                animate={{ rotate: 360 }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "linear",
                  delay: i * 0.2
                }}
              >
                <div 
                  className="w-1 h-1 bg-purple-500 rounded-full absolute"
                  style={{
                    top: '50%',
                    left: '50%',
                    transform: `rotate(${i * 90}deg) translateX(${24 + i * 8}px)`
                  }}
                />
              </motion.div>
            ))}

            <div className="absolute inset-0 overflow-hidden rounded-full">
              {Array.from({ length: 10 }).map((_, i) => (
                <motion.div
                  key={i}
                  className="absolute w-full h-[1px] bg-gradient-to-r from-transparent via-purple-500/30 to-transparent"
                  initial={{ y: -10 }}
                  animate={{ y: 150 }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    delay: i * 0.1,
                    ease: "linear"
                  }}
                  style={{
                    top: `${(i * 10)}%`,
                  }}
                />
              ))}
            </div>
          </motion.div>
        </motion.div>

        <div className="relative mt-8">
          <div className="h-2 bg-purple-900/30 rounded-full overflow-hidden backdrop-blur-sm">
            <motion.div
              className={`h-full ${
                powerBoost 
                  ? 'bg-gradient-to-r from-yellow-500 via-purple-500 to-blue-500' 
                  : 'bg-gradient-to-r from-purple-500 to-blue-500'
              }`}
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.5 }}
            />
          </div>
          
          <motion.div
            className="absolute top-0 left-0 w-20 h-full"
            animate={{
              x: [-100, 400],
              opacity: [0, 1, 0]
            }}
            transition={{
              duration: powerBoost ? 1 : 2,
              repeat: Infinity,
              ease: "linear"
            }}
            style={{
              background: `linear-gradient(90deg, 
                transparent 0%, 
                ${powerBoost ? '#facc15' : '#a855f7'}50 50%, 
                transparent 100%)`
            }}
          />
        </div>

        <div className="space-y-8">
          <div className="flex justify-between text-sm">
            <span className={`${powerBoost ? 'text-yellow-400' : 'text-purple-400'} 
              flex items-center gap-2`}>
              <Zap className={powerBoost ? 'animate-pulse' : ''} />
              {powerBoost ? 'BOOST AKTIF!' : 'Yükleniyor...'}
            </span>
            <span className="text-purple-400">{progress}%</span>
          </div>
        </div>
              
        <AnimatePresence mode="wait">
          {loadingPhases.map((phase, index) => {
            const Icon = phase.icon;
            return currentPhase === index ? (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className={`flex items-center gap-3 ${phase.color}`}
              >
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: powerBoost ? 1 : 2, repeat: Infinity, ease: "linear" }}
                >
                  <Icon size={18} />
                </motion.div>
                <span className="text-sm">{phase.text}</span>
              </motion.div>
            ) : null;
          })}
        </AnimatePresence>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
          className="mt-4 text-center"
        >
          <span className={`text-purple-400/70 text-sm bg-purple-500/10 px-4 py-2 rounded-lg backdrop-blur-sm ${
            powerBoost ? 'text-yellow-400/70 bg-yellow-500/10' : ''
          }`}>
            {clickCount < 5 ? 
              "Ekrana tıkla ve sistemi hızlandır!" : 
              powerBoost ? 
                "Boost modu aktif! Yükleme hızlandırıldı!" : 
                "Biraz daha tıkla..."
            }
          </span>
        </motion.div>
      </div>

      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {Array.from({ length: 30 }).map((_, i) => (
          <motion.div
            key={i}
            className={`absolute font-mono text-sm
              ${powerBoost ? 'text-yellow-500/20' : 'text-purple-500/20'}`}
            style={{
              left: `${i * 3.33}%`,
              fontSize: `${Math.random() * 10 + 10}px`
            }}
            initial={{ y: -100 }}
            animate={{ y: '100vh' }}
            transition={{
              duration: powerBoost ? 1 : Math.random() * 2 + 1,
              repeat: Infinity,
              repeatType: "loop",
              ease: "linear",
              delay: Math.random() * 2
            }}
          >
            {Math.random().toString(36).substr(2, 1)}
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default CyberLoading;